import React from 'react';
import PropTypes from 'prop-types';

const DropdownMenu = ({ title, list = [], className, children }) => {
    const emptyListClass = !list.length ? 'iw-dropdown-menu--empty-list' : '';

    if (list.length === 0)
        return (
            <div className={`iw-dropdown-menu ${emptyListClass} ${className}`}>
                <span className="iw-dropdown-menu__title">
                    {title || children}
                </span>
            </div>
        );

    return (
        <div className={`iw-dropdown-menu ${emptyListClass} ${className}`}>
            <div className="iw-dropdown-menu__content">
                <span className="iw-dropdown-menu__title">
                    {title || children}
                </span>
                <ul className="iw-dropdown-menu__list">
                    {list.map((page) => {
                        // Generating link
                        let link;

                        if (page.onClick) {
                            link = (
                                <a
                                    href="#_"
                                    onClick={() => page.onClick()}
                                    className="iw-dropdown-menu__link">
                                    <span>{page.pageName || page.title}</span>
                                </a>
                            );
                        } else if (page.url) {
                            link = (
                                <a
                                    href={page.url}
                                    className="iw-dropdown-menu__link">
                                    <span>{page.pageName || page.title}</span>
                                </a>
                            );
                        } else {
                            link = (
                                <a href="#_" className="iw-dropdown-menu__link">
                                    <span>{page.pageName || page.title}</span>
                                </a>
                            );
                        }

                        return (
                            <li
                                key={page.id}
                                className="iw-dropdown-menu__item">
                                {link}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

DropdownMenu.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
    className: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            url: PropTypes.string,
            onClick: PropTypes.func,
            title: PropTypes.string.isRequired
        })
    ).isRequired
};

DropdownMenu.defaultProps = {
    className: ''
};

export default DropdownMenu;
