import React from 'react';
import ReactMarkdown from 'react-markdown';
import Sticky from 'react-stickynode';
import { Link, Element } from 'react-scroll';
import styles from './QuickJumpContentBlocks.module.scss';

function QuickJumpContentBlocks({ data }) {
    const headerHeight = 73;

    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <div className={styles.navContainer}>
                    <Sticky
                        enabled={true}
                        top={headerHeight}
                        bottomBoundary=".quick-jump-section__content-container:last-child .quick-jump-section__inner-content-container:last-child p">
                        <nav className={styles.nav}>
                            <ul className={styles.primaryNav}>
                                {data.contentBlocks &&
                                    data.contentBlocks.map(
                                        (contentBlock, index) => (
                                            <li key={contentBlock.id}>
                                                <Link
                                                    activeClass={
                                                        styles.navLinkActive
                                                    }
                                                    to={contentBlock.id}
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-headerHeight}
                                                    duration={500}
                                                    className={styles.navLink}
                                                    index={index}>
                                                    {contentBlock.title}
                                                </Link>
                                                {contentBlock.contentBlocks && (
                                                    <ul
                                                        className={
                                                            styles.secondaryNav
                                                        }>
                                                        {contentBlock.contentBlocks.map(
                                                            (
                                                                innerContentBlock,
                                                                index
                                                            ) => (
                                                                <li
                                                                    key={
                                                                        innerContentBlock.id
                                                                    }>
                                                                    <Link
                                                                        activeClass={
                                                                            styles.navLinkActive
                                                                        }
                                                                        to={
                                                                            innerContentBlock.id
                                                                        }
                                                                        spy={
                                                                            true
                                                                        }
                                                                        smooth={
                                                                            true
                                                                        }
                                                                        offset={
                                                                            -headerHeight
                                                                        }
                                                                        duration={
                                                                            500
                                                                        }
                                                                        className={
                                                                            styles.navLink
                                                                        }
                                                                        index={
                                                                            index
                                                                        }>
                                                                        {
                                                                            innerContentBlock.title
                                                                        }
                                                                    </Link>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                            </li>
                                        )
                                    )}
                            </ul>
                        </nav>
                    </Sticky>
                </div>

                <QuickJumpContentContainer data={data} />
            </div>
        </div>
    );
}

const QuickJumpContentContainer = ({ data }) => {
    return (
        <div className={styles.allContentsContainer}>
            {data.introduction && (
                <p className={styles.introduction}>
                    <ReactMarkdown
                        source={data.introduction.introduction}
                        escapeHtml={false}
                    />
                </p>
            )}
            {data.contentBlocks &&
                data.contentBlocks.map((contentBlock, index) => (
                    <ContentBlock
                        contentBlock={contentBlock}
                        key={contentBlock.id}
                    />
                ))}
        </div>
    );
};

function ContentBlock({ contentBlock }) {
    return (
        <Element name={contentBlock.id} className={styles.contentContainer}>
            <h2 className={styles.title}>
                {contentBlock.title && contentBlock.title}
            </h2>
            {contentBlock.content && (
                <p>
                    <ReactMarkdown
                        className="quick-jump-section__content-text"
                        source={contentBlock.content.content}
                        escapeHtml={false}
                    />
                </p>
            )}
            {contentBlock.contentBlocks &&
                contentBlock.contentBlocks.map((innerContentBlock, index) => (
                    <Element
                        key={index}
                        id={innerContentBlock.id}
                        className={styles.innerContentContainer}>
                        <h3 className={styles.title}>
                            {innerContentBlock.title}
                        </h3>
                        <p>
                            <ReactMarkdown
                                className="quick-jump-section__content-text"
                                source={innerContentBlock.content.content}
                                escapeHtml={false}
                            />
                        </p>
                    </Element>
                ))}
        </Element>
    );
}

export default QuickJumpContentBlocks;
