import React from 'react';
import { graphql } from 'gatsby';

import styles from './QuickJump.module.scss';
import iwocaPageStyles from '../../uk/iwocaPage.module.scss';
import MetaData from '../../../components/Layout/MetaData';
import NavBar from '../../../components/NavBar/NavBar';
import Footer from '../../../components/Footer/Footer';
import QuickJumpContentBlocks from './QuickJumpContentBlocks/QuickJumpContentBlocks';
import HeroBannerSmall from '../../../components/common/HeroBanner/HeroBannerSmall';

const QuickJump = ({ data }) => {
    const { contentfulPageQuickJumpContent: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping = true,
        url,
        pageContent
    } = pageData;

    return (
        <div className={iwocaPageStyles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />

            <NavBar />

            <div className={styles.wrapper}>
                {pageContent.sections.map((section, index) => {
                    switch (section.__typename) {
                        case 'ContentfulSectionHeroBannerSmall':
                            return (
                                <HeroBannerSmall
                                    key={index}
                                    data={section}
                                    currentPage={pageData}
                                />
                            );
                        case 'ContentfulSectionQuickJumpContentBlocks':
                            return (
                                <QuickJumpContentBlocks
                                    key={index}
                                    data={section}
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </div>
            <Footer />
        </div>
    );
};

export const query = graphql`
    query QuickJumpContentPageQuery($id: String!) {
        contentfulPageQuickJumpContent(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            pageContent {
                sections {
                    __typename
                    ... on ContentfulSectionHeroBannerSmall {
                        shortTitle
                        shortTitleMenu {
                            menuList {
                                ... on ContentfulLink {
                                    id
                                    title
                                    url
                                    icon {
                                        file {
                                            url
                                        }
                                    }
                                }
                                url
                            }
                        }
                        headline
                        subtitleText: subtitle {
                            subtitle
                        }
                        content {
                            __typename
                            ... on ContentfulHeroImage {
                                keyword
                                image {
                                    file {
                                        url
                                    }
                                }
                                highlightColour
                            }
                        }
                        button {
                            ... on ContentfulButton {
                                id
                                dataGaId
                                buttonText
                                url
                                icon {
                                    file {
                                        url
                                    }
                                }
                                isDownloadable
                            }
                        }
                        selectTextAlignment
                        selectTextColourScheme
                    }
                    ... on ContentfulSectionQuickJumpContentBlocks {
                        introduction {
                            introduction
                        }
                        contentBlocks {
                            id
                            title
                            content {
                                id
                                content
                            }
                            contentBlocks {
                                id
                                title
                                content {
                                    id
                                    content
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default QuickJump;
